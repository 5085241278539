/// <reference path="../../../globals.d.ts" />
import React from 'react';
import Script from 'next/script';
import env from '@ui/env';
import { getCookie } from '@ui/hooks/useCookie';

type SSIntelliSuggestProps = {
  productSku: string;
};

const SSIntelliSuggestProductView = ({ productSku }: SSIntelliSuggestProps): React.ReactElement => (
  <Script
    type="text/javascript"
    src="https://cdn.searchspring.net/intellisuggest/is.min.js"
    id="searchspring-intellisuggest-product-view"
    onLoad={() => {
      const shopperID = getCookie('ssUserId');

      IntelliSuggest.init({
        siteId: env.NEXT_PUBLIC_SEARCHSPRING_SITEID,
        context: `Product/${productSku}`,
        seed: [productSku],
      });
      IntelliSuggest.setShopperId(shopperID as string);
      IntelliSuggest.viewItem({ sku: productSku });
    }}
  />
);

export default SSIntelliSuggestProductView;

import { useEffect, useRef, useState } from 'react';
import Image from '@ui/components/core/image';
import { ReactZoomPanPinchContentRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

type GalleryImageProps = {
  initialScale: number;
  imageUrl: string;
  imageAlt: string;
  onPanChanged?: (enabled: boolean) => void;
};

export default function GalleryImage({ initialScale, imageUrl, imageAlt, onPanChanged }: GalleryImageProps) {
  const transformerRef = useRef<ReactZoomPanPinchContentRef>(null);
  const [isPanEnabled, setIsPanEnabled] = useState(false);

  useEffect(() => {
    transformerRef.current?.centerView(initialScale);
  }, [initialScale]);

  if (initialScale === -1) {
    return null;
  }

  return (
    <TransformWrapper
      centerOnInit
      initialScale={initialScale}
      minScale={initialScale}
      ref={transformerRef}
      panning={{
        disabled: !isPanEnabled,
      }}
      onZoomStop={(e) => {
        const originalScale = Math.round(initialScale * 100);
        const currentScale = Math.round(e.state.scale * 100);

        if (originalScale >= currentScale) {
          setIsPanEnabled(false);
          onPanChanged?.(false);
          e.centerView(initialScale);
        } else {
          setIsPanEnabled(true);
          onPanChanged?.(true);
        }
      }}
    >
      <TransformComponent wrapperClass="!h-full">
        <Image
          className="md:w-screen md:h-auto"
          src={imageUrl}
          alt={imageAlt}
          height={1200}
          width={800}
        />
      </TransformComponent>
    </TransformWrapper>
  );
}

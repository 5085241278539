import React, { useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { FacebookIcon, FacebookShareButton, PinterestIcon, PinterestShareButton } from 'react-share';
import { useRouter } from 'next/router';
import { ShareIcon, TextInput } from '@ui/components/core';
import { Button } from "@ui/components/core/button"
import { Heading } from '../../typography';
import { useValidProducts } from '@ui/store/recentlyViewedStore';
import { toast } from '@ui/hooks/use-toast';
import Dialog from '@ui/components/core/dialog';

const ShareButton = (): React.ReactElement => {
  const recentlyViewed = useValidProducts();

  const router = useRouter();

  const [shareUrl, setShareUrl] = useState('');

  useEffect(() => {
    setShareUrl(window.location.href);
  }, [router.query.product]);

  const [shareModalOpened, { toggle: shareModalOpen, close: shareModalClose }] = useDisclosure(false);

  const copy = async () => {
    await navigator.clipboard.writeText(window.location.href);
  };

  return (
    <>
      <Button
        variant="unstyled"
        className="md:text-[13px] font-bold gap-x-2 no-underline uppercase"
        leftIcon={<ShareIcon width={13} height={16} />}
        onClick={shareModalOpen}
      >
        <span className="hidden md:block">Share</span>
      </Button>
      <Dialog.Root open={shareModalOpened} onOpenChange={shareModalClose}>
        <Dialog.Content className="p-4 w-[24.8125rem] rounded-[0.25rem] lg:rounded-[0.25rem]" 
        classNames={{
          overlay: 'p-0',
          close: 'absolute top-4 right-4',
        }}
        aria-describedby='share-dialog'
        >
          <Dialog.Title className="sr-only">Share Button</Dialog.Title>
          <div className='flex flex-col gap-y-6'>
            <Heading asChild>
              <h4 className='text-semibold text-sm text-center'>
                Share this item
              </h4>
            </Heading>
            <div className='w-full'>
              <div className='flex group'>
                <TextInput
                  w="100%"
                  size="lg"
                  value={shareUrl}
                  contentEditable={false}
                  className="w-[73%] rounded-l-[2.08px] rounded-r-none"
                />
                <Button
                  variant="outline"
                  className="bg-black text-white w-[27%] rounded-l-none rounded-r-[2.08px] h-[3.125rem]"
                  onClick={() => {
                    toast({
                      description: 'Link copied!',
                      variant: 'success',
                    })

                    copy();
                  }}
                >
                  Copy
                </Button>
              </div>
            </div>
            <div className='w-full'>
              <FacebookShareButton url={shareUrl} style={{ width: '100%' }}>
                <Button
                  variant="outline"
                  leftIcon={<FacebookIcon size={32} round />}
                  className="w-full md:text-[13px] h-12 font-normal gap-x-[0.625rem]"
                >
                  Share via Facebook
                </Button>
              </FacebookShareButton>
              {recentlyViewed?.filter((product) => product.handle === (router.query.product as string))[0] &&
                recentlyViewed?.filter((product) => product.handle === (router.query.product as string))[0].imageUrl && (
                  <PinterestShareButton
                    media={
                      recentlyViewed?.filter((product) => product.handle === (router.query.product as string))[0].imageUrl
                    }
                    url={shareUrl}
                    style={{ width: '100%' }}
                  >
                    <Button
                      variant="outline"
                      className="w-full md:text-[13px] h-12 font-normal gap-x-[0.625rem]"
                      leftIcon={<PinterestIcon size={32} round />}
                    >
                      Share via Pinterest
                    </Button>
                  </PinterestShareButton>
                )}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};

export default ShareButton;

import React, { useEffect,useRef,useState } from 'react';
import { SwiperCarousel, SwiperSlide, SwiperRef} from '@ui/components/core/carousel';
import { LeftChevronIcon, RightChevronIcon } from '@ui/components/core';
import cn from '@ui/utils/cn';
import GalleryImage from './GalleryImage';
import { ServerProductProps } from '@ui/nextServer';
import Dialog from '@ui/components/core/dialog';
import { Button } from '@ui/components/core/button';

type GalleryModalProps = {
  images: ServerProductProps['props']['product']['images'];
  opened: boolean;
  onClose: () => void;
};

const GalleryModal = ({ images, opened, onClose }: GalleryModalProps): React.ReactElement => {
  const carouselRef = useRef<SwiperRef>(null);
  const prevButton = useRef<HTMLButtonElement>(null);
  const nextButton = useRef<HTMLButtonElement>(null);
  const [current, setCurrent] = React.useState(0)
  const [initialScale, setInitialScale] = useState(-1);
  const [isPanEnabled, setIsPanEnabled] = useState(false);
  
   useEffect(() => {
    const handleResize = () => {
      if (carouselRef.current?.swiper) {
        const { width, height } = carouselRef.current?.swiper;
        setInitialScale(height / (width * 1.5));
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(handleResize);
    });

    if (carouselRef.current) {
      resizeObserver.observe(carouselRef.current);
    }

    return () => {
      if (carouselRef.current) {
        resizeObserver.unobserve(carouselRef.current);
      }
    };
  }, [carouselRef.current]);


  return (
    <Dialog.Root open={opened} onOpenChange={onClose}>
      <Dialog.Title className='sr-only'>Gallery Modal</Dialog.Title>
      <Dialog.Content className='h-full overflow-hidden p-0 w-full max-w-[791px]' classNames={{
        overlay: 'bg-white p-0',
        close: 'z-[2]'
      }}
      aria-describedby='gallery-modal'
      >
          
        <div className="p-4 md:p-6 top-0 inset-x-0 z-[1] absolute w-fit pointer-events-none">
          <div className="text-xs font-bold w-fit">
            {current} / {images?.length}
          </div>
        </div>
        <div className={cn('px-4 md:px-6 h-[100dvh] inset-x-0 z-[1] absolute w-full flex justify-between items-center pointer-events-none transition',isPanEnabled && 'opacity-0')}>
          <Button ref={prevButton} variant='unstyled' className='p-4 pointer-events-auto disabled:opacity-[0%] h-[70%]'>
            <LeftChevronIcon height={20} width={20} />
          </Button>
          <Button ref={nextButton} variant='unstyled' className='p-4 pointer-events-auto disabled:opacity-[0%] h-[70%]'>
            <RightChevronIcon height={20} width={20} />
          </Button>
        </div>

          
        <SwiperCarousel parentRef={carouselRef} 
          onSlideInit={(swiper) => {
            setCurrent(swiper.realIndex + 1)
            setInitialScale(swiper.height / (swiper.width * 1.5));
          }}
          onSlideChange={(swiper) => {
            setCurrent(swiper.realIndex + 1)
          }}
          buttons={{
            prev: prevButton,
            next: nextButton
          }}
          >
            {images?.map((image) => (
              <SwiperSlide key={image.id} autoHeight>
                <div className='flex overflow-visible h-[100dvh] items-center justify-center'>
                  <GalleryImage
                    initialScale={initialScale}
                    imageAlt={image.altText || ''}
                    imageUrl={image.url}
                    onPanChanged={setIsPanEnabled}
                  />
                </div>
              </SwiperSlide>
            ))}
          </SwiperCarousel>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default GalleryModal;

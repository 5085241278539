import React from 'react';
import { ProductCardCarousel } from '@ui/components/shared';
import personalizedRecommendations from '@ui/axios/searchSpring/personalizedRecommendations';
import { useQuery } from '@tanstack/react-query';
import { Product, recommendationProductsToSearchProducts } from '@ui/helpers/recommendationProductsToSearchProducts';
import { getCookie } from '@ui/hooks/useCookie';
import cn from '@ui/utils/cn';
import { shuffle } from 'lodash';
import { ServerProductProps } from '@ui/nextServer';
import { GetProducts } from '@client-shopify/gql/storefront/api/queries';
import { getCustomerCountry } from '@ui/hooks/useCustomerCountry';

type SectionProps = {
  className?: string;
  tags: string;
  title: string;
  limits?: number;
  controls: boolean;
  product?: ServerProductProps['props']['product'];
  alignTitleToCarousel?: boolean;
};

const ProductDetailsCarousel = ({
  className,
  title,
  tags,
  limits,
  controls,
  product,
  alignTitleToCarousel,
}: SectionProps): React.ReactElement => {
  const productIdentifier = product?.variants?.[0]?.sku || product?.id?.split('/').pop();

  const fetchData = async (
    tags: string,
    shopper: string,
    cart: string,
    lastViewed: string,
    limits?: number,
    productSku?: string,
  ) => {
    const shuffleResults = tags === 'cross-sell' || tags.startsWith('pdp-style-with');

    const res = await personalizedRecommendations({
      tags,
      shopper,
      cart,
      lastViewed,
      limits: shuffleResults ? 100 : limits,
      productId: productSku,
    });

    if (shuffleResults) {
      res.data[0].results = shuffle(res.data[0].results).slice(0, limits);
    }

    return res;
  };

  const { data, isLoading, error } = useQuery({
    queryKey: [tags, productIdentifier],
    queryFn: async () => {
      const searchSpringData = await fetchData(
        tags,
        getCookie('shopper'),
        getCookie('cart'),
        getCookie('lastViewed'),
        limits,
        productIdentifier,
      );

      const searchSpringResults = searchSpringData.data[0].results as Array<Product>;

      if (searchSpringResults.length > 0) {
        const productsFromShopify = await GetProducts({
          first: searchSpringResults.length,
          query: searchSpringResults.map((p) => `id:${p.mappings.core.uid}`).join(' OR '),
          country: getCustomerCountry(),
        });

        searchSpringResults.forEach((product) => {
          const productEdges = productsFromShopify?.data?.products.edges || [];
          const productNode = productEdges.find((p) => p.node.id.split('/').pop() === product.mappings.core.uid);
          if (!productNode) return;

          product.mappings.core.price = productNode.node.priceRange.maxVariantPrice.amount;
          product.mappings.core.currency = productNode.node.priceRange.maxVariantPrice.currencyCode;
          product.mappings.core.msrp = productNode.node.compareAtPriceRange.maxVariantPrice.amount;

          const images = productNode.node.images.edges.map((image) => image.node.url);
          product.attributes.images = images;
          product.mappings.core.imageUrl = images[0];
        });
      }

      return searchSpringData;
    },
  });

  if (error || isLoading) return <></>;

  return (
    <div className={className}>
      <div className={cn('text-[14px] font-bold uppercase mb-4', alignTitleToCarousel && 'pl-2')}>{title}</div>
      <div className="md:px-6">
        <ProductCardCarousel
          withControls={controls}
          swiperOptions={{
            spaceBetween: 16,
            slidesPerView: 2.2,
            breakpoints: {
              640: { slidesPerView: 2.2 },
              768: { slidesPerView: 3.2 },
              1024: { slidesPerView: 4.2 },
              1200: { slidesPerView: 5.2 },
          }}}
          carousel={{
            tag: tags,
            placement: 'product-page',
            products: recommendationProductsToSearchProducts(data?.data[0].results.slice(0, limits)),
          }}
          height={315}
          width={210}
          layout="product"
        />
      </div>
    </div>
  );
};

export default ProductDetailsCarousel;

import React, { useRef, useState } from 'react';
import { LeftChevronIcon, RightChevronIcon, ZoomIcon } from '@ui/components/core';
import CarouselIndicators from '../CarouselIndicators/CarouselIndicators';
import { ServerProductProps } from '@ui/nextServer';
import { Button } from '@ui/components/core/button';
import { SwiperCarousel, SwiperRef, SwiperSlide } from '@ui/components/core/carousel';

type ProductImageCarouselProps = {
  images: ServerProductProps['props']['product']['images'];
  onItemClick?: (image: any) => void;
};

const ProductImageCarousel = ({ images, onItemClick }: ProductImageCarouselProps): React.ReactElement => {
  const [activeSlide, setActiveSlide] = useState(0);
  const carouselRef = useRef<SwiperRef>(null);
  const prevButton = useRef<HTMLButtonElement>(null);
  const nextButton = useRef<HTMLButtonElement>(null);

  return (
    <div className='relative'>
      <div className='z-[1] absolute w-full flex justify-between items-center pointer-events-none transition top-1/2 left-0 -translate-y-1/2'>
        <Button ref={prevButton} variant='unstyled' className='p-4 ml-4 pointer-events-auto disabled:opacity-[0%]'>
          <LeftChevronIcon height={20} width={20} />
        </Button>
        <Button ref={nextButton} variant='unstyled' className='p-4 mr-4 pointer-events-auto disabled:opacity-[0%]'>
          <RightChevronIcon height={20} width={20} />
        </Button>
      </div>
      <div className='relative flex'>
        <SwiperCarousel
          parentRef={carouselRef}
          onSlideInit={(swiper) => {
              setActiveSlide(swiper.realIndex)
          }}
          onSlideChange={(swiper) => {
            setActiveSlide(swiper.realIndex)
          }}
          buttons={{
            prev: prevButton,
            next: nextButton
          }}
          speed={300}
        >
          {images?.map((image, i) => (
              <SwiperSlide key={image.id} autoHeight>
                <div className="relative pb-[150%] isolate" onClick={() => onItemClick?.(image)}>
                  <picture>
                    <img
                      className="absolute w-full h-full object-cover"
                      src={image.url ?? ('' as string)}
                      alt={image.altText ?? ('' as string)}
                      width={320}
                      height={480}
                      loading={i > 1 ? 'lazy' : 'eager'}
                    />
                  </picture>
                </div>
              </SwiperSlide>
            ))}
        </SwiperCarousel>
      </div>
      <Button
        variant='unstyled'
        onClick={() => onItemClick?.(images[activeSlide])}
        className="w-6 h-6 absolute top-4 right-4 z-10 active:bg-transparent"
      >
        <ZoomIcon width={20} height={20} />
      </Button>
      <CarouselIndicators
        className="absolute -bottom-6 md:opacity-0"
        activeSlide={activeSlide}
        totalSlides={images?.length || 0}
      />
    </div>
  );
};

ProductImageCarousel.defaultProps = {
  onItemClick: undefined,
};

export default ProductImageCarousel;

import { useDisclosure } from '@mantine/hooks';
import { GalleryModal, ProductImageCarousel, ProductImageGrid, ShareButton } from '../../../shared';
import ProductDetailsStyleWithCarousel from '../ProductDetailsStyleWithCarousel/ProductStyleWithCarousel';
import { ServerProductProps } from '@ui/nextServer/';

type ProductDetailsLeftSectionProps = {
  product: ServerProductProps['props']['product'];
  carousel?: { tag: string; placement: string; products: Array<ServerProductProps['props']['product']> };
};

const ProductDetailsLeftSection = ({ product }: ProductDetailsLeftSectionProps) => {
  const images = product.images || [];
  const [galleryOpened, { open: galleryOpen, close: galleryClose }] = useDisclosure(false);

  if (!images) {
    return null;
  }

  return (
    <div>
      <div className="hidden md:block">
        <ProductImageGrid images={images} onItemClick={() => galleryOpen()} />
        <ProductDetailsStyleWithCarousel
          limits={4}
          controls={false}
          className="mt-11"
          product={product}
        />
      </div>
      <div className="md:hidden relative mb-8">
        <ProductImageCarousel images={images} onItemClick={galleryOpen} />
        <div className="absolute -bottom-12 right-6 hidden">
          <ShareButton />
        </div>
      </div>
      <GalleryModal images={images} opened={galleryOpened} onClose={galleryClose} />
    </div>
  );
};

export default ProductDetailsLeftSection;

import React from 'react';
import { FilledStarIcon } from '../../../core';
import { Button } from "@ui/components/core/button"
import cn from '@ui/utils/cn';

type ReviewRatingsProp = {
  className?: string;
  value: number;
  children?: React.ReactNode;
  onChange?: (newValue: number) => void;
  hideEmptyStars?: boolean;
};

const ReviewRatings = ({
  className,
  value,
  children,
  onChange,
  hideEmptyStars,
}: ReviewRatingsProp): React.ReactElement => {
  return (
    <div className={cn('flex space-x-2 [&_[data-slot=icon]]:size-4', className)}>
      <div data-slot="buttons" className="inline-flex space-x-1">
        {[1, 2, 3, 4, 5]
          .filter((score) => !hideEmptyStars || value >= score)
          .map((score) => (
            <Star key={score} value={score} checked={value >= score} onChange={onChange} />
          ))}
      </div>
      {children && (
        <div data-slot="inner" className="text-xs">
          {children}
        </div>
      )}
    </div>
  );
};

export default ReviewRatings;

function Star({
  value,
  checked,
  onChange,
}: {
  value: number;
  checked: boolean;
  onChange?: (newValue: number) => void;
}) {
  const icon = <FilledStarIcon data-slot="icon" className={checked ? 'text-[#FEDCEC]' : 'text-white'} />;

  return onChange ? (
    <Button onClick={() => onChange(value)} variant="unstyled" className="no-underline" type="button">
      {icon}
    </Button>
  ) : (
    <span>{icon}</span>
  );
}

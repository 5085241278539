import React from 'react';
import { ProductCardCarousel } from '../../../shared';

type SectionProps = {
  products: any[];
};

const ProductDetailsRecentlyViewedSection = ({ products }: SectionProps) => {
  if (!products.length) {
    return null;
  }

  return (
    <div>
      <div className="text-[14px] font-bold uppercase mb-4">RECENTLY VIEWED</div>
      <div className="md:px-6">
        <ProductCardCarousel
          carousel={{
            tag: '',
            placement: 'product-page',
            products,
          }}
          
          swiperOptions={{
            spaceBetween: 16,
            slidesPerView: 2.2,
            breakpoints: {
              640: { slidesPerView: 2.2 },
              768: { slidesPerView: 3.2 },
              1024: { slidesPerView: 4.2 },
              1200: { slidesPerView: 5.2 },
          }}}
          height={315}
          width={210}
          withControls
          layout="product"
        />
      </div>
    </div>
  );
};

export default ProductDetailsRecentlyViewedSection;

import React from 'react';
import { useDisclosure } from '@mantine/hooks';
import { AfterpayIcon, Badge, CompactRightChevronIcon, KlarnaIcon, Portal, Prose } from '../../../core';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@ui/components/core/accordion';
import {
  CartUpsellCarousel,
  FinalSaleModal,
  ProductBrandFromTags,
  SizeSelector,
  WishlistButton,
  WearNowPayLaterDialog,
  Money,
  AddedToCart,
  WhereIsMySizeSheet,
  SizeGuideSheet,
  SizeSelectorSheet,
  ProductBadge,
} from '../../../shared';
import { Button } from '@ui/components/core/button';
import { useRouter } from 'next/router';
import env from '@ui/env';
import OutOfStock from '../../../shared/labels/OutOfStock/OutOfStock';
import AddToCartButton from './AddToCartButton';
import cn from '@ui/utils/cn';
import ReviewSummary from './ReviewSummary';
import DOMPurify from 'dompurify';
import Link from 'next/link';
import { StaticContent } from '@ui/providers';
import { useStaticContent } from '@ui/providers/static-content-provider';
import { ServerProductProps } from '@ui/nextServer';
import { useElevarDataLayer } from '@ui/hooks/useElevarDataLayer';
import { useQuery } from '@tanstack/react-query';
import { GetProductPricing } from '@client-shopify/gql/storefront/api/queries';
import { getCustomerCountry } from '@ui/hooks/useCustomerCountry';
import useYotpoDataLayer from '@ui/hooks/useYotpoDataLayer';
import ProductTolstoyModule from '../ProductTolstoyModule/ProductTolstoyModule';
import { useInView } from 'react-intersection-observer';
import useAddToCart from '@ui/hooks/useAddToCart';
import { useModalStore } from '@ui/store.export';
import Image from '@ui/components/core/image';
import { shouldUseGridLayout } from '@ui/components/shared/selectors/SizeSelector/SizeSelector';
import PetSizeGuideSheet from '@ui/components/shared/sheets/SizeGuideSheet/PetSizeGuideSheet';
import { getProductBadge, isOnSale } from '@ui/helpers/productHelpers';
import useRecentlyViewedStore from '@ui/store/recentlyViewedStore';
type ProductDetailsSectionProps = {
  product: ServerProductProps['props']['product'];
};

type Variant = ServerProductProps['props']['product']['variants'][number];

const ProductDetailsRightSection = ({ product }: ProductDetailsSectionProps): React.ReactElement => {
  const router = useRouter();
  const tagMapping = useStaticContent('Product.TagCollections');
  const { yotpoAddedToCart } = useYotpoDataLayer();
  const { elevarAddToCartEvent } = useElevarDataLayer();
  const ssViewedProduct = useRecentlyViewedStore((state) => state.ssViewedProduct);

  const [selectedVariant, setSelectedVariant] = React.useState<Variant>();
  const [selectedSSVariantSku, setSelectedSSVariantSku] = React.useState<string>();

  const activeVariant = React.useMemo(
    () => selectedVariant || (product?.variants ?? [])[0],
    [selectedVariant, product],
  );

  const [finalSaleModalOpened, { toggle: finalSaleModalOpen, close: finalSaleModalClose }] = useDisclosure(false);

  const [descriptionHtml, setDescriptionHtml] = React.useState('');

  React.useEffect(() => {
    setDescriptionHtml(DOMPurify.sanitize(product.descriptionHtml));
  }, [product.descriptionHtml]);

  const outOfStockVariants =
    product?.variants?.filter((v) => Number(v.quantityAvailable) < 1 || !v.availableForSale) || [];

  const totalEffectivelyAvailableProductStock = product?.variants?.reduce(
    (accumulatedStock, variant) =>
      accumulatedStock + (!variant.availableForSale ? 0 : Number(variant.quantityAvailable) - 0),
    0,
  );

  const isProductEffectivelyOutOfStock: boolean = totalEffectivelyAvailableProductStock === 0;

  const [openWearNowPayLaterDialog, setOpenWearNowPayLaterDialog] = React.useState(false);
  const payLaterChannels = useStaticContent('WearNowPayLater.Channels');

  const { data: pricing } = useQuery({
    queryKey: ['productPricing', product.handle],
    enabled: env.FEATURE_MULTICURRENCY,
    queryFn: async () =>
      await GetProductPricing({
        handle: product.handle,
        country: getCustomerCountry(),
      }),
  });

  const price = env.FEATURE_MULTICURRENCY ? pricing?.data?.product?.priceRange.maxVariantPrice : activeVariant?.price;

  const compareAtPrice = env.FEATURE_MULTICURRENCY
    ? pricing?.data?.product?.compareAtPriceRange.maxVariantPrice
    : activeVariant?.compareAtPrice;

  const [isAfterPaySupported, setIsAfterPaySupported] = React.useState(false);

  React.useEffect(() => {
    const customerCountry = getCustomerCountry();
    setIsAfterPaySupported(['AU', 'US', 'NZ'].includes(customerCountry));
  }, []);

  const [openSizeGuideSheet, setOpenSizeGuideSheet] = React.useState(false);
  const [openWhereIsMySizeSheet, setOpenWhereIsMySizeSheet] = React.useState(false);
  const [openSelectSizeSheet, setOpenSelectSizeSheet] = React.useState(false);
  const [openStickyAddToBag, setOpenStickyAddToBag] = React.useState(false);
  const openStickyAddToBagOnce = React.useRef(false);

  const { ref: topAnchorRef, entry: belowTitleEntry } = useInView({ rootMargin: '0px 0px -90px 0px' });
  const { ref: midAnchorRef, entry: midAnchorEntry } = useInView({ rootMargin: '-60px 0px 0px 0px' });

  React.useEffect(() => {
    openStickyAddToBagOnce.current = false;
    setOpenStickyAddToBag(false);
  }, [product]);

  React.useEffect(() => {
    if (!openStickyAddToBag) return;
    if (openStickyAddToBagOnce.current) return;
    openStickyAddToBagOnce.current = true;
  }, [openStickyAddToBag]);

  React.useEffect(() => {
    if (!belowTitleEntry) return;
    if (!belowTitleEntry.isIntersecting) return;
    if (openStickyAddToBagOnce.current) return;
    setOpenStickyAddToBag(true);
  }, [belowTitleEntry]);

  React.useEffect(() => {
    if (!midAnchorEntry) return;

    if (midAnchorEntry.isIntersecting) {
      setOpenStickyAddToBag(false);
    } else if (openStickyAddToBagOnce.current) {
      setOpenStickyAddToBag(true);
    }
  }, [midAnchorEntry]);

  function handleAddedToCart() {
    yotpoAddedToCart(activeVariant.id, 1);
    elevarAddToCartEvent(
      [
        {
          url: router.asPath,
          list: router.asPath,
          position: 1,
          compare_at_price: activeVariant.compareAtPrice?.amount.toString() ?? '0',
          brand: product.vendor,
          category: 'Clothes',
          id: activeVariant.sku ?? '',
          name: product.title,
          price: product.price.amount.toString(),
          product_id: product.id.split('/').at(-1) ?? '',
          quantity: '1',
          variant: activeVariant.sku.split('-').at(-1) ?? '',
          variant_id: activeVariant.id.split('/').at(-1) ?? '',
          image: product.images[0].url ?? '',
        },
      ],
      router.asPath,
    );
    setSelectedVariant(undefined);
    setSelectedSSVariantSku(undefined);
  }

  const {
    addToCart,
    isLoading: isAddingToCart,
    recentlyAddedToCart,
  } = useAddToCart({
    onSuccess: () => handleAddedToCart(),
  });

  const openModal = useModalStore((state) => state.openModal);

  React.useEffect(() => {
    recentlyAddedToCart && openModal('cart');
  }, [recentlyAddedToCart, openModal]);

  const onSale = price && isOnSale(price.amount, compareAtPrice?.amount);
  const productBadgeText = getProductBadge(product.tags);

  return (
    <>
      <div
        className={cn(
          'p-4 md:sticky md:top-28 md:pl-1 md:pb-28 md:pt-8 md:pr-8 md:-mt-8 md:-mr-8',
          'md:h-[calc(100vh-72px)]',
          'md:overflow-x-hidden md:overflow-y-scroll',
          'md:scrollbar-none',
        )}
      >
        <div>
          {activeVariant && (
            <div>
              <div ref={topAnchorRef} className="space-y-1">
                <ProductBrandFromTags tags={product?.tags} tagMapping={tagMapping} />
                <div className="flex items-start justify-between">
                  <h1 className="tracking-[0.4px] font-bold uppercase">{product.title}</h1>
                  <WishlistButton className="size-5 mt-0.5" product={product} variant={activeVariant} />
                </div>
              </div>
              {price && (
                <div className="mt-3 flex flex-col space-y-2 lg:flex-row lg:items-center lg:space-x-2 lg:space-y-0">
                  <div className="flex items-center space-x-2">
                    {onSale && (
                      <div className="text-[14px] tracking-[0.4px] text-[#727272] line-through">
                        <Money value={compareAtPrice?.amount} currency={compareAtPrice?.currencyCode} />
                      </div>
                    )}
                    <div className={cn('text-[14px] tracking-[0.4px]', onSale && 'text-[#FF0D00]')}>
                      <Money value={price.amount} currency={price.currencyCode} />
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    {product?.tags?.includes('final-sale') && (
                      <Badge
                        color="red.9"
                        bg="#FF0D00"
                        variant="filled"
                        radius="xs"
                        px="0.25rem"
                        sx={{ cursor: 'default' }}
                        onClick={finalSaleModalOpen}
                        className="cursor-pointer"
                      >
                        FINAL SALE
                      </Badge>
                    )}
                    {productBadgeText && <ProductBadge text={productBadgeText} />}
                  </div>
                </div>
              )}
              {env.FEATURE_PRODUCT_BNPL && isAfterPaySupported && (
                <div className="flex items-center space-x-2 mt-2">
                  <div className="text-xs">
                    4 x {env.NEXT_PUBLIC_REGION}${activeVariant.bnplPrice.amount} with
                  </div>
                  <div className="flex items-center space-x-2">
                    {payLaterChannels.includes('afterpay') && <AfterpayIcon height={12} width={63} />}
                    {payLaterChannels.length > 1 && <span className="text-xs">or</span>}
                    {payLaterChannels.includes('klarna') && <KlarnaIcon height={10} width={45} />}
                  </div>
                  <Button
                    variant="unstyled"
                    className="text-xs underline focus:outline-none"
                    onClick={() => setOpenWearNowPayLaterDialog(true)}
                  >
                    More Info
                  </Button>
                  <WearNowPayLaterDialog
                    open={openWearNowPayLaterDialog}
                    onClose={() => setOpenWearNowPayLaterDialog(false)}
                  />
                </div>
              )}
            </div>
          )}
          <div className="relative pb-6 md:pb-4">
            <ReviewSummary className="block mt-[14px] absolute" product={product} hideWhenEmpty />
          </div>
        </div>

        <div className="mt-8">
          <div className="flex flex-wrap -m-2">
            {Array.isArray(product.siblings) &&
              product.siblings.map((sibling) => (
                <ColorSwatchImg
                  parentHandle={product.handle ?? ''}
                  key={sibling.handle}
                  handle={sibling.handle}
                  id={sibling.middlewareId}
                  unavailable={!sibling.availableForSale}
                />
              ))}
          </div>
        </div>

        <div className="mt-8">
          <SizeSelector
            layout={shouldUseGridLayout(product) ? 'grid' : 'flex'}
            product={product}
            value={selectedVariant}
            setVariant={(variantToAdd) => {
              const newSelectedVariant = variantToAdd
                ? product.variants.find((v) => v.id === variantToAdd.id)
                : undefined;

              setSelectedVariant(newSelectedVariant);
            }}
          />

          {outOfStockVariants.length > 0 && (
            <div className="flex mt-5">
              <Button
                variant="unstyled"
                className="md:text-xs tracking-[0.004em] no-underline"
                onClick={() => setOpenWhereIsMySizeSheet(true)}
              >
                <span>Where&apos;s my size? Notify me</span>
                <CompactRightChevronIcon className="size-3 ml-1" />
              </Button>
            </div>
          )}

          <div ref={midAnchorRef} className="mt-[26px]">
            {isProductEffectivelyOutOfStock ? (
              <OutOfStock />
            ) : (
              <AddToCartButton
                label="Add to bag"
                selectedVariant={selectedVariant}
                selectedSSVariantSku={selectedSSVariantSku}
                onSuccess={() => handleAddedToCart()}
              />
            )}
          </div>

          <div className="mt-2 text-[14px] tracking-[0.6px] text-center">
            <StaticContent id="Product.FreeShipping" inline />
          </div>
        </div>

        <Accordion className="mt-6" type="single" collapsible>
          <AccordionItem className="border-black" value="size-guide">
            {product.productType !== 'Accessories' && (
              <Button
                onClick={() => setOpenSizeGuideSheet(true)}
                variant="unstyled"
                className="!text-[14px] no-underline w-full py-4 justify-between uppercase"
              >
                <span>Size guide and model size</span>
                <CompactRightChevronIcon className="size-[0.9rem] mr-1" />
              </Button>
            )}
          </AccordionItem>
          <AccordionItem className="border-black" value="details">
            <AccordionTrigger className="uppercase">Details</AccordionTrigger>
            <AccordionContent>
              <Prose
                className="text-[14px] [&_*]:tracking-[0.6px]"
                dangerouslySetInnerHTML={{ __html: descriptionHtml }}
              />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem className="border-black" value="delivery-and-returns">
            <AccordionTrigger className="uppercase">Delivery and Returns</AccordionTrigger>
            <AccordionContent>
              <StaticContent id="Product.DeliveryAndReturns" />
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        {env.FEATURE_TOLSTOY && <ProductTolstoyModule productId={product.id} tags={product.tags} />}

        <CartUpsellCarousel className="px-6 -mx-4 md:mx-0 bg-pink-8 mt-9" beaconPlacement="product-page" />
      </div>

      <Portal
        className={cn(
          'md:hidden transform fixed bottom-0 w-full p-4 pt-0 bg-white/80 transition duration-300 z-10',
          openStickyAddToBag && !isProductEffectivelyOutOfStock && !openSelectSizeSheet
            ? 'translate-y-0'
            : 'translate-y-full',
        )}
      >
        <div className="relative overflow-hidden">
          <Button onClick={() => setOpenSelectSizeSheet(true)} className="w-full" loading={isAddingToCart}>
            Add to bag
          </Button>
          <AddedToCart show={recentlyAddedToCart} fill />
        </div>
      </Portal>

      <FinalSaleModal opened={finalSaleModalOpened} onClose={finalSaleModalClose} />

      <WhereIsMySizeSheet
        product={product}
        open={openWhereIsMySizeSheet}
        onClose={() => {
          setOpenWhereIsMySizeSheet(false);
        }}
      />

      {product.tags.includes('category_pets') ? (
        <PetSizeGuideSheet
          product={product}
          open={openSizeGuideSheet}
          onClose={() => {
            setOpenSizeGuideSheet(false);
          }}
        />
      ) : (
        <SizeGuideSheet
          product={product}
          open={openSizeGuideSheet}
          onClose={() => {
            setOpenSizeGuideSheet(false);
          }}
        />
      )}

      <SizeSelectorSheet
        layout={shouldUseGridLayout(product) ? 'grid' : 'flex'}
        product={product}
        open={openSelectSizeSheet}
        onClose={() => {
          setOpenSelectSizeSheet(false);
        }}
        onSelect={(variantToAdd) => {
          addToCart({
            productVariantId: variantToAdd.id,
            intellisuggestData: ssViewedProduct?.intellisuggestData,
            intellisuggestSignature: ssViewedProduct?.intellisuggestSignature,
          });
        }}
      />
    </>
  );
};

export default ProductDetailsRightSection;

const ColorSwatchImg = ({
  parentHandle,
  handle,
  id,
  unavailable,
}: {
  parentHandle: string;
  handle: string;
  id: string;
  unavailable?: boolean;
}): React.ReactNode => {
  const [hidden, setHidden] = React.useState(false);

  return (
    !hidden && (
      <Link
        key={id}
        href={`/products/${handle}`}
        className={cn(
          handle === parentHandle && 'ring-1 ring-offset-2 rounded-full ring-black',
          'relative inline-flex items-center justify-center m-2',
        )}
      >
        <Image
          alt={handle}
          className={cn('rounded-full w-6 h-6', unavailable && 'opacity-30')}
          height={32}
          onError={() => setHidden(true)}
          src={`https://assets.hellomolly.com/samples/${id}.webp`}
          width={32}
        />
        {unavailable && (
          <svg
            className="w-5 h-5 absolute pointer-events-none"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15.071 15.3865L0.928909 1.24434" stroke="#9E9E9E" strokeWidth="1.2" strokeLinecap="round" />
          </svg>
        )}
      </Link>
    )
  );
};

import React, { useRef, useEffect, useState } from 'react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { getProductReviews, type GetProductReviewsArgs } from '@ui/axios/yotpo/getProductReviews';
import {
  HeightFilterMappings,
  SizeFilterMappings,
  type ProductFilters,
} from '@ui/axios/yotpo/getFilteredProductReviews';
import { getTopMentionedTopics } from '@ui/axios/yotpo/getTopMentionedTopics';
import {
  CloseIcon,
  DownChevronIcon,
  FilterIcon,
  LeftChevronIcon,
  RightChevronIcon,
  Sheet,
  VerifiedBadgeIcon,
} from '@ui/components/core';
import Dialog from "@ui/components/core/dialog"
import { ReviewRatings, SimplePagination, Spinner, Toggle } from '@ui/components/shared';
import { Button } from "@ui/components/core/button"
import { LoadingOverlay, Menu } from '@mantine/core';
import ProductReviewCard from './ProductReviewCard';
import ProductReviewForm from './ProductReviewForm';
import env from '@ui/env';
import cn from '@ui/utils/cn';
import { ServerProductProps } from '@ui/nextServer';
import ProductFitChart from './ProductFitChart';
import Image from '@ui/components/core/image';
import { getCanonicalProductId, normalizeHeight, normalizeSize, unescapeHtmlEntities } from './functions';
import { mean, orderBy } from 'lodash';
import { SwiperCarousel, SwiperRef, SwiperSlide } from '@ui/components/core/carousel';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@ui/components/core/accordion';

type Product = ServerProductProps['props']['product'];

type ProductDetailsReviewSectionProps = {
  product: Product;
};

type SortOption = {
  sort: GetProductReviewsArgs['sort'];
  direction: GetProductReviewsArgs['direction'];
};

const SortMappings: Record<string, SortOption> = {
  'Most Recent': { sort: 'created_at', direction: 'desc' },
  'Highest Rating': { sort: 'score', direction: 'desc' },
  'Lowest Rating': { sort: 'score', direction: 'asc' },
};

const ProductDetailsReviewsSection = ({ product }: ProductDetailsReviewSectionProps) => {
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('Most Recent');
  const [filters, setFilters] = useState({} as ProductFilters);

  const { data, isLoading } = useReviews({
    product,
    page,
    sort,
    filters,
    fetchAll: true,
  });

  useEffect(() => {
    if (!product.handle) return;
    setPage(1);
    setFilters({});
    setSort('Most Recent');
  }, [product.handle]);

  const pagination = data.pagination;
  const bottomline = data.bottomline;
  const reviews = data.reviews;

  const photos = useReviewPhotos(product);
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [openSummary, setOpenSummary] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);

  function handleSort(newSort: string) {
    setSort(newSort);
    setPage(1);
  }


  function handlePaginate(newPage: number) {
    setPage(newPage);
    const isMobile = !window.matchMedia('(min-width: 62em)').matches;
    isMobile && document.getElementById('review-actions')?.scrollIntoView({ behavior: 'smooth' });
  }

  function handleClearFilters() {
    setFilters({} as ProductFilters);
    setPage(1);
  }

  function handleRemoveFilter(filter: keyof ProductFilters) {
    const newFilters = { ...filters };
    delete newFilters[filter];
    setFilters(newFilters);
    setPage(1);
  }

  return (
    <div id="reviews" className="scroll-mt-12">
      {isLoading ? (
        <div className="py-12">
          <Spinner className="size-6 border-2" />
        </div>
      ) : (
        <div>
          <h1 className="text-[14px] font-bold uppercase">CUSTOMER REVIEWS</h1>

          {reviews.length > 0 ? (
            <div className="flex justify-between max-md:flex-wrap gap-8 md:gap-14 mt-4">
              <div className="w-full md:w-1/3">
                <div className="text-[24px] leading-none font-normal">{`${bottomline.average_score.toFixed(1)}/5`}</div>
                <div className="mt-3">
                  <ReviewRatings value={bottomline.average_score}>
                    based on {bottomline.total_review} {bottomline.total_review === 1 ? 'review' : 'reviews'}
                  </ReviewRatings>
                </div>
                <div className="mt-5 flex max-lg:flex-wrap gap-3">
                  <Button
                    onClick={() => setOpenForm(true)}
                    className="px-4 !text-[14px] w-auto lg:w-1/2"
                    variant="outline"
                  >
                    <span>Write a review</span>
                    <RightChevronIcon className="size-4 ml-2" />
                  </Button>
                  {bottomline.total_review > 0 && (
                    <Button
                      onClick={() => setOpenSummary(true)}
                      className="px-4 !text-[14px] w-auto lg:w-1/2 hidden"
                      variant="outline"
                    >
                      See review summary
                    </Button>
                  )}
                </div>
              </div>
              {bottomline.average_fit > 0 && (
                <div className="w-full md:w-1/3">
                  <div className="text-[14px] font-bold tracking-[0.004em] uppercase">True to size</div>
                  <div className="mt-4">
                    <ProductFitChart className="[&_[data-slot=dot]]:size-2" value={bottomline.average_fit} />
                  </div>
                </div>
              )}
              <div className="w-full md:w-1/3">
                <CustomerPhotosSection product={product} />
              </div>
            </div>
          ) : (
            <div className="mt-4">
              <div className="text-[14px] tracking-[0.6px]">
                This item doesn’t have any reviews yet. Be the first to write one.
              </div>
              <Button onClick={() => setOpenForm(true)} className="px-4 !text-[14px] mt-4" variant="outline">
                <span>Write a review</span>
                <RightChevronIcon className="size-4 ml-2" />
              </Button>
            </div>
          )}

          {reviews.length > 0 && (
            <div className="relative isolate">
              <LoadingOverlay
                className="pt-12 items-start md:pt-0 md:items-center"
                visible={isLoading}
                overlayBlur={1}
              />

              <div id="review-actions" className="mt-11 flex justify-between scroll-mt-20">
                <Button className="px-4 h-10 space-x-3" variant="outline" onClick={() => setOpenFilter(true)}>
                  <span>Filter</span>
                  <FilterIcon className="size-6" />
                </Button>
                <SortMenu value={sort} onChange={handleSort} />
              </div>

              {Object.keys(filters).length > 0 && (
                <div className="flex flex-wrap gap-2 mt-5">
                  <Button
                    variant="outline"
                    className="px-2 py-1 h-auto !text-xs font-normal uppercase"
                    onClick={handleClearFilters}
                    type="button"
                  >
                    Clear all ({Object.keys(filters).length})
                  </Button>
                  {Object.keys(filters).map((key) => (
                    <span
                      key={key}
                      className="inline-flex items-center p-1 pl-2 border border-black text-xs uppercase rounded bg-[#FCC6E1]"
                    >
                      {key === 'media' ? 'Has Media' : `${key}: ${filters[key as keyof ProductFilters]}`}
                      <Button
                        variant='unstyled'
                        className="ml-1 inline-flex max-md:touch-target"
                        onClick={() => handleRemoveFilter(key as keyof ProductFilters)}
                        type="button"
                      >
                        <CloseIcon className="size-4" />
                      </Button>
                    </span>
                  ))}
                </div>
              )}

              <div className="mt-5 flex max-md:flex-wrap">
                {reviews.map((review, i) => (
                  <React.Fragment key={review.id}>
                    <Separator show={i > 0} />
                    <ProductReviewCard
                      className="w-full md:w-1/3"
                      review={review}
                      onImageClick={(image) => {
                        setActiveSlide(photos.findIndex((photo) => photo.image.id === image.id));
                        setOpenDialog(true);
                      }}
                    />
                  </React.Fragment>
                ))}
              </div>

              {env.FEATURE_PRODUCT_REVIEWS_PAGINATION && (
                <div className='flex justify-center md:justify-end'>
                  <SimplePagination 
                    currentPage={page}
                    totalPages={Math.ceil(pagination.total / 3)}
                    handleNextPage={() => handlePaginate(page + 1)}
                    handlePreviousPage={() => handlePaginate(page - 1)}
                    setPage={handlePaginate}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <ReviewFilterSheet
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        product={product}
        value={filters}
        onValueChange={(newFilters) => {
          setFilters(newFilters);
          setPage(1);
        }}
      />

      <CustomerPhotosDialog
        product={product}
        open={openDialog}
        initialSlide={activeSlide}
        onClose={() => {
          setOpenDialog(false);
        }}
      />

      <ReviewSummarySheet
        product={product}
        open={openSummary}
        onClose={() => {
          setOpenSummary(false);
        }}
      />

      <WriteReviewSheet
        product={product}
        open={openForm}
        onClose={() => {
          setOpenForm(false);
        }}
      />
    </div>
  );
};

export default ProductDetailsReviewsSection;

function Separator({ show }: { show: boolean }) {
  return show && <div className="border-[#B3B3B3] max-md:border-b max-md:w-full max-md:my-6 md:mx-6 md:border-r" />;
}

function SortMenu({ value, onChange }: { value: string; onChange: (newValue: string) => void }) {
  return (
    <Menu
      position="bottom-end"
      classNames={{
        dropdown: 'border border-black p-0',
        item: 'px-3 py-2 data-[hovered=true]:bg-white data-[hovered=true]:underline',
      }}
    >
      <Menu.Target>
        <Button className="px-4 h-10 group space-x-3" variant="outline">
          <span>{value}</span>
          <DownChevronIcon className="size-4 group-data-[expanded]:rotate-180 transition" />
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={() => onChange('Most Recent')}>Most Recent</Menu.Item>
        <Menu.Item onClick={() => onChange('Highest Rating')}>Highest Rating</Menu.Item>
        <Menu.Item onClick={() => onChange('Lowest Rating')}>Lowest Rating</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

function ReviewFilterSheet({
  open,
  onClose,
  product,
  value,
  onValueChange,
}: {
  open: boolean;
  onClose: () => void;
  product: Product;
  value: ProductFilters;
  onValueChange: (value: ProductFilters) => void;
}) {
  const [filterRating, setFilterRating] = React.useState(value.rating || '');
  const [filterHeight, setFilterHeight] = React.useState(value.height || '');
  const [filterSize, setFilterSize] = React.useState(value.size || '');
  const [filterMedia, setFilterMedia] = React.useState(value.media || false);

  React.useEffect(() => {
    setFilterRating(value.rating || '');
    setFilterHeight(value.height || '');
    setFilterSize(value.size || '');
    setFilterMedia(value.media || false);
  }, [value]);

  function handleReset() {
    setFilterRating('');
    setFilterHeight('');
    setFilterSize('');
    setFilterMedia(false);
  }

  function handleApply() {
    const newValue: ProductFilters = {};
    if (filterRating) newValue.rating = filterRating;
    if (filterHeight) newValue.height = filterHeight;
    if (filterSize) newValue.size = filterSize;
    if (filterMedia) newValue.media = true;

    onValueChange(newValue);
    onClose();
  }

  const { data } = useReviews({
    product,
    page: 1,
    sort: '',
    filters: {
      rating: filterRating || undefined,
      height: filterHeight || undefined,
      size: filterSize || undefined,
      media: filterMedia,
    },
  });

  return (
    <Sheet.Root open={open} onOpenChange={onClose}>
      <Sheet.Content
        side="dynamic"
        withCloseButton={false}
        className="isolate flex flex-col overflow-hidden p-0 md:max-w-[493px]"
      >
        <div className="p-4 md:p-6 flex-1 w-full overflow-y-auto">
          <div className="pb-4 flex items-center justify-between border-b border-[#B3B3B3]">
            <Sheet.Title>Filter</Sheet.Title>
            <Sheet.Description className="sr-only">Filter reviews</Sheet.Description>
            <Sheet.Close />
          </div>

          <Accordion type="multiple">
            <AccordionItem value="rating">
              <AccordionTrigger>Rating</AccordionTrigger>
              <AccordionContent>
                <div className="space-y-2">
                  {['1', '2', '3', '4', '5'].map((value) => (
                    <div key={value}>
                      <Button
                        onClick={() => setFilterRating(filterRating === value ? '' : value)}
                        variant="outline"
                        className="border-none h-auto p-2.5 data-[checked]:bg-[#FFF1F7] md:hover:bg-[#FFF1F7] md:active:bg-[#FFF1F7]"
                        data-checked={filterRating === value || undefined}
                      >
                        <ReviewRatings value={+value} hideEmptyStars />
                      </Button>
                    </div>
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="height">
              <AccordionTrigger>Height</AccordionTrigger>
              <AccordionContent>
                <div className="space-y-4">
                  {Object.keys(HeightFilterMappings).map((value) => (
                    <Toggle
                      key={value}
                      className="space-x-3"
                      pressed={value === filterHeight}
                      onPressedChange={(checked) => setFilterHeight(checked ? value : '')}
                    >
                      <span className="text-[13px] font-medium">{value}</span>
                    </Toggle>
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="size">
              <AccordionTrigger>Size</AccordionTrigger>
              <AccordionContent>
                <div className="space-y-4">
                  {product.variants.map((variant) => (
                    <Toggle
                      key={variant.size}
                      className="space-x-3"
                      pressed={variant.size === filterSize}
                      onPressedChange={(checked) => setFilterSize(checked ? variant.size : '')}
                    >
                      <span className="text-[13px] font-medium">{variant.size}</span>
                    </Toggle>
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem className='border-none' value="media">
              <AccordionTrigger>Media</AccordionTrigger>
              <AccordionContent>
                <Toggle
                  className="space-x-3 normal-case"
                  pressed={filterMedia}
                  onPressedChange={(checked) => setFilterMedia(checked)}
                >
                  <span className="text-[13px] font-medium">Has images and videos</span>
                </Toggle>
              </AccordionContent>
            </AccordionItem>
          </Accordion>

        </div>
        <div className="p-4 flex justify-between space-x-4 border-t border-[#B3B3B3]">
          <Button className="w-full" variant="outline" onClick={handleReset}>
            Reset
          </Button>
          <Button className="w-full" onClick={handleApply} disabled={!data.pagination.total}>
            View ({data.pagination.total})
          </Button>
        </div>
      </Sheet.Content>
    </Sheet.Root>
  );
}

function CustomerPhotosSection({ product }: { product: Product }) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [activeSlide, setActiveSlide] = React.useState(0);

  const { data } = useReviews({
    product,
    perPage: 999,
    filters: { media: true },
  });

  if (!data.reviews.length) {
    return null;
  }

  return (
    <div>
      <CustomerPhotosCarousel
        product={product}
        onSelectSlide={(index) => {
          setActiveSlide(index);
          setOpenDialog(true);
        }}
      />
      <CustomerPhotosDialog
        product={product}
        initialSlide={activeSlide}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
      />
    </div>
  );
}

function CustomerPhotosCarousel({
  className,
  onSelectSlide,
  product,
}: {
  className?: string;
  onSelectSlide: (index: number) => void;
  product: Product;
}) {
  const photos = useReviewPhotos(product);

  const swiperRef = useRef<SwiperRef>(null);
  const prev = useRef<HTMLButtonElement>(null);
  const next = useRef<HTMLButtonElement>(null);

  return (
    <div className={cn('space-y-4', className)}>
      <div className="flex justify-between">
        <div className="text-[14px] font-bold tracking-[0.004em] uppercase">Customer Photos</div>
        <div className="flex space-x-2">
          <Button
            ref={prev}
            className="size-6 p-0 rounded-full text-white disabled:text-black disabled:bg-[#f5f5f5] disabled:border-[#f5f5f5]"
          >
            <LeftChevronIcon className="size-4" />
          </Button>
          <Button
            ref={next}
            className="size-6 p-0 rounded-full text-white disabled:text-black disabled:bg-[#f5f5f5] disabled:border-[#f5f5f5]"
          >
            <RightChevronIcon className="size-4" />
          </Button>
        </div>
      </div>
      <div className='relative flex'>
        <SwiperCarousel parentRef={swiperRef} spaceBetween={16} slidesPerView={3.5} breakpoints={
          {
            992: {
              slidesPerView: 6,
            }
        }}
        buttons={{
          prev,
          next,
        }}
        >
          {photos.map((photo, i) => (
            <SwiperSlide key={photo.image.id}>
              <Button
                variant='unstyled'
                className="w-full flex"
                onClick={() => {
                  onSelectSlide(i);
                }}
              >
                <Image
                  className="w-full h-auto object-cover"
                  loading="eager"
                  width={150}
                  height={150}
                  src={photo.image.thumb_url}
                  alt=""
                />
              </Button>
            </SwiperSlide>
          ))}
        </SwiperCarousel>
      </div>
    </div>
  );
}

function CustomerPhotosDialog({
  open,
  onClose,
  product,
  initialSlide = 0,
}: {
  open: boolean;
  onClose: () => void;
  initialSlide?: number;
  product: Product;
}) {
  const photos = useReviewPhotos(product);
  const carouselRef = useRef<SwiperRef>(null);
  const prev = useRef<HTMLButtonElement>(null);
  const next = useRef<HTMLButtonElement>(null);

  const [activeSlide, setActiveSlide] = useState(initialSlide);

  const activeReview = photos[activeSlide]?.review;
  const fit = Object.values(activeReview?.custom_fields || {}).find((cf) => cf.title === 'Fit');
  const size = Object.values(activeReview?.custom_fields || {}).find((cf) => cf.title === 'Size');
  const height = Object.values(activeReview?.custom_fields || {}).find((cf) => cf.title === 'Height');

  return (
    activeReview && (
      <Dialog.Root open={open} onOpenChange={onClose}>
        <Dialog.Content
          classNames={{
            overlay: 'max-md:p-0',
            root: 'p-4 max-md:flex max-md:flex-col max-md:min-h-full md:max-w-[732px] md:rounded-lg',
            close: 'max-md:hidden',
          }}
          aria-description='customer-photos-dialog'
        >
          <div className="w-full">
            <div className="pb-4 flex items-center justify-between border-b border-[#B3B3B3] md:hidden">
              <Dialog.Title>Customer Photos</Dialog.Title>
              <Sheet.Close />
            </div>
            <div className="flex flex-col md:grid w-full max-md:mt-5 max-md:gap-6 md:gap-4 md:grid-cols-2 md:h-[512px]">
              <div className="flex w-full bg-gray-100 relative">
                <SwiperCarousel parentRef={carouselRef}
                  buttons={{
                    prev,
                    next,
                  }}
                  initialSlide={initialSlide}
                  onSlideChange={(swiper) => {
                    setActiveSlide(swiper.realIndex)
                  }}
                  loop
                >
                  {photos.map((photo) => (
                    <SwiperSlide
                      key={photo.image.id}
                    >
                      <div className="isolate w-full relative max-md:bg-black max-md:pb-[150%] h-[unset] md:h-full">
                        <picture>
                          <img
                            alt=""
                            className="w-full h-full object-contain md:object-cover absolute z-10"
                            src={photo.image.original_url}
                          />
                        </picture>
                      </div>                      
                    </SwiperSlide>
                  ))}
                </SwiperCarousel>

                <div className='absolute top-1/2 left-0 w-full flex justify-between items-center pointer-events-none z-[10]'>
                  <Button ref={prev} variant='unstyled' 
                    className='size-6 p-0 ml-4 pointer-events-auto rounded-full bg-black text-white disabled:text-black disabled:bg-[#f5f5f5] disabled:border-[#f5f5f5]'
                  >
                    <LeftChevronIcon height={16} width={16} />
                  </Button>
                  <Button ref={next} variant='unstyled' 
                    className='size-6 p-0 mr-4 pointer-events-auto rounded-full bg-black text-white disabled:text-black disabled:bg-[#f5f5f5] disabled:border-[#f5f5f5]'
                  >
                    <RightChevronIcon height={16} width={16} />
                  </Button>
                </div>
              </div>
              <div className="md:pt-10 md:max-h-full md:overflow-y-auto">
                <div className="space-y-5 md:space-y-6">
                  <div>
                    <div className="space-y-3 max-md:hidden">
                      <div className="flex items-baseline gap-2">
                        <div className="w-full">
                          <div className="font-semibold uppercase tracking-[0.004em]">
                            {activeReview.user.display_name}
                          </div>
                          {activeReview.verified_buyer && (
                            <div className="flex items-center mt-1.5">
                              <div className="text-xs tracking-[0.004em]">Verified Buyer</div>
                              <VerifiedBadgeIcon className="size-3.5 ml-1.5" />
                            </div>
                          )}
                        </div>
                        <time className="text-xs tracking-[0.004em]" dateTime={activeReview.created_at}>
                          {new Date(activeReview.created_at).toLocaleDateString()}
                        </time>
                      </div>
                      <ReviewRatings value={activeReview.score} />
                    </div>
                    <div className="md:hidden">
                      <div className="flex items-start justify-between">
                        <div className="text-[14px] font-semibold uppercase tracking-[0.004em]">
                          {activeReview.user.display_name}
                        </div>
                        <ReviewRatings value={activeReview.score} />
                      </div>
                      <div className="flex items-start justify-between">
                        <div>
                          {activeReview.verified_buyer && (
                            <div className="flex items-center mt-1.5">
                              <div className="text-xs tracking-[0.004em]">Verified Buyer</div>
                              <VerifiedBadgeIcon className="size-3.5 ml-1.5" />
                            </div>
                          )}
                        </div>
                        <div>
                          <time className="text-xs tracking-[0.004em]" dateTime={activeReview.created_at}>
                            {new Date(activeReview.created_at).toLocaleDateString()}
                          </time>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="text-[14px] font-bold tracking-[0.6px] uppercase">
                      {unescapeHtmlEntities(activeReview.title)}
                    </div>
                    <div className="text-[14px] tracking-[0.6px] leading-relaxed mt-2">
                      {unescapeHtmlEntities(activeReview.content)}
                    </div>
                    {(size || height) && (
                      <div className="mt-2 flex space-x-4">
                        {size && (
                          <div className="flex space-x-2 text-xs tracking-[0.4px]">
                            <span className="font-bold">Size:</span>
                            <span>{normalizeSize(size.value)}</span>
                          </div>
                        )}
                        {height && (
                          <div className="flex space-x-2 text-xs tracking-[0.4px]">
                            <span className="font-bold">Height:</span>
                            <span>{normalizeHeight(height.value)}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {fit && <ProductFitChart className="w-[220px]" value={Number(fit.value)} />}
                </div>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Root>
    )
  );
}

function ReviewSummarySheet({ open, onClose, product }: { open: boolean; onClose: () => void; product: Product }) {
  const { data } = useReviewTopics(product);

  const topics = (data?.data.top_topics.top_mention_topics || []).filter((topic) => {
    const topicsToShow = ['fit', 'look', 'quality', 'material', 'fabric'];
    return topicsToShow.includes(topic.name);
  });

  return (
    <Sheet.Root open={open} onOpenChange={onClose}>
      <Sheet.Content
        side="dynamic"
        withCloseButton={false}
        className="isolate flex flex-col overflow-hidden p-4 md:p-6 md:max-w-[493px]"
      >
        <div className="flex-1 w-full overflow-y-auto">
          <div className="pb-4 flex items-center justify-between border-b border-[#B3B3B3]">
            <Sheet.Title>Review Summary</Sheet.Title>
            <Sheet.Close />
          </div>
          <div className="mt-5 space-y-6">
            {data === null && <Spinner className="size-6 border-2" />}
            {data !== null && topics.length === 0 && <div className="text-center">No data to show at the moment.</div>}
            {topics.map((topic) => (
              <div key={topic.name}>
                <h1 className="tracking-[0.004em] font-semibold uppercase">{topic.name}</h1>
                <p className="tracking-[0.004em] leading-relaxed mt-3">
                  Based on a significant number of customers, the general consensus is that the product has a great{' '}
                  {topic.name}.
                </p>
                <Button onClick={() => onClose()} variant="unstyled" className="text-xs no-underline mt-3 items-end">
                  <span>Read reviews</span>
                  <RightChevronIcon className="size-4 ml-1" />
                </Button>
              </div>
            ))}
          </div>
        </div>
        <div className="pt-4 flex items-center justify-between border-t border-[#B3B3B3]">
          <Button onClick={() => onClose()} variant="unstyled" className="text-xs no-underline mt-3 items-end">
            <span>Read all reviews</span>
            <RightChevronIcon className="size-4 ml-1" />
          </Button>
          <div className="text-sm">
            Powered by <b className="text-[#0042e4]">Yotpo API</b>
          </div>
        </div>
      </Sheet.Content>
    </Sheet.Root>
  );
}

function WriteReviewSheet({ open, onClose, product }: { open: boolean; onClose: () => void; product: Product }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(true);

  React.useEffect(() => {
    if (!open) return;
    setIsLoading(false);
    setIsSuccess(false);
  }, [open]);

  return (
    <Sheet.Root open={open} onOpenChange={onClose}>
      <Sheet.Content
        side="dynamic"
        withCloseButton={false}
        className="isolate flex flex-col overflow-hidden p-0 md:max-w-[493px]"
      >
        <div className="p-4 md:p-6 flex-1 w-full overflow-y-auto">
          <div className="pb-4 flex items-center justify-between border-b border-[#B3B3B3]">
            <Sheet.Title>Write a review</Sheet.Title>
            <Sheet.Close />
          </div>
          <div className="mt-5">
            {isSuccess ? (
              <div>
                <h1 className="font-semibold tracking-[0.004em]">THANK YOU FOR YOUR REVIEW</h1>
                <p className="tracking-[0.004em] mt-4">
                  Please note all reviews are screened for profanity and offensive material before being published.
                </p>
              </div>
            ) : (
              <ProductReviewForm
                onLoading={() => setIsLoading(true)}
                onComplete={() => setIsLoading(false)}
                onSuccess={() => setIsSuccess(true)}
                product={{
                  id: product.id ?? '',
                  title: product.title ?? '',
                  handle: product.handle ?? '',
                  url: `${env.NEXT_PUBLIC_BASE_URL}/products/${product.handle}/`,
                  sizes: product.variants.map((variant) => variant.size),
                }}
              />
            )}
          </div>
        </div>
        <div className="p-4 flex justify-between space-x-4 border-t border-[#B3B3B3]">
          {isSuccess ? (
            <Button variant="outline" className="w-full" onClick={() => onClose()}>
              Close
            </Button>
          ) : (
            <Button form="create-review-form" type="submit" className="w-full" loading={isLoading}>
              Submit
            </Button>
          )}
        </div>
      </Sheet.Content>
    </Sheet.Root>
  );
}

function useReviews({
  product,
  page = 1,
  perPage = 3,
  sort = 'Most Recent',
  filters = {},
  fetchAll = false,
}: {
  product: Product;
  page?: number;
  perPage?: number;
  sort?: string;
  filters?: ProductFilters;
  fetchAll?: boolean;
}) {
  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ['pdpAllReviews', product.handle],
    initialPageParam: 1,
    queryFn: async ({ pageParam }) =>
      getProductReviews({
        productId: await getCanonicalProductId(product),
        perPage: 150,
        page: pageParam,
      }),
    getNextPageParam: (lastPage) => {
      const { pagination } = lastPage.data.response;

      if (pagination.page * pagination.per_page < pagination.total) {
        return pagination.page + 1;
      } else {
        return null;
      }
    },
  });

  // Due to Yotpo's API limitations, we are fetching all product reviews on render.
  // Pagination, filtering, and sorting features are all handled within this hook.

  React.useEffect(() => {
    if (!fetchAll) return;
    if (!hasNextPage) return;
    if (isFetchingNextPage) return;
    fetchNextPage();
  }, [
    fetchAll, //
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  ]);

  const reviews = data?.pages.flatMap((page) => page.data.response.reviews) || [];

  const filtered = reviews.filter((review) => {
    let isMatch = true;

    if (filters.height) {
      const height = Object.values(review.custom_fields || {}).find((cf) => cf.title === 'Height')?.value || '';
      isMatch = HeightFilterMappings[filters.height].includes(unescapeHtmlEntities(height));
    }

    if (filters.size) {
      const size = Object.values(review.custom_fields || {}).find((cf) => cf.title === 'Size')?.value || '';
      isMatch = SizeFilterMappings[filters.size].includes(unescapeHtmlEntities(size));
    }

    if (filters.rating) {
      isMatch = filters.rating === String(review.score);
    }

    if (filters.media) {
      isMatch = (review.images_data || []).length > 0;
    }

    return isMatch;
  });

  const sorted = SortMappings[sort]
    ? orderBy(filtered, SortMappings[sort].sort, SortMappings[sort].direction)
    : filtered;

  return {
    isLoading: isLoading || hasNextPage,
    data: {
      bottomline: {
        average_score: data?.pages[0].data.response.bottomline.average_score || 0,
        total_review: data?.pages[0].data.response.bottomline.total_review || 0,
        average_fit: mean(
          reviews
            .map((review) => Number(Object.values(review.custom_fields || {}).find((cf) => cf.title === 'Fit')?.value))
            .filter((fit) => Number.isInteger(fit)),
        ),
      },
      pagination: {
        total: filtered.length,
        page: page,
        per_page: perPage,
      },
      reviews: sorted.slice((page - 1) * perPage, page * perPage),
    },
  };
}

function useReviewPhotos(product: Product) {
  const { data } = useReviews({
    product,
    perPage: 999,
    filters: { media: true },
  });

  return data.reviews.flatMap((review) =>
    review.images_data!.map((image) => ({
      review,
      image,
    })),
  );
}

function useReviewTopics(product: Product) {
  return useQuery({
    queryKey: ['topMentionedTopics', product.handle],
    queryFn: async () =>
      getTopMentionedTopics({
        productId: await getCanonicalProductId(product),
      }),
  });
}
